import React from 'react';
import {AfmAppBar} from "./components/AfmAppBar/AfmAppBar";
import {Navigate, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import "./styles/App.scss";
import {FleetAlertsPage} from "./pages/FleetAlerts/FleetAlertsPage";
import ReportsLanding from "./pages/ReportsLanding/ReportsLanding";
import AdministrationLanding from "./pages/AdministrationLanding/AdministrationLanding";
import {AddUserLanding} from "./pages/AdministrationLanding/UserManagement/AddUser/AddUserLanding";
import CompressorsPage from "./pages/CompressorsPage/CompressorsPage";
import {CompressorLandingPage} from "./pages/CompressorLanding/CompressorLandingPage";
import FaultTroubleShootingRepository from "./pages/FaultTroubleShootingRepository/FaultTroubleShootingRepository";
import CompressorAlertsTab from "./pages/CompressorLanding/CompressorAlertsTab/CompressorAlertsTab";
import ChartsTab from "./pages/CompressorLanding/ChartsTab/ChartsTab";
import CompressorConditionsSummary from "./pages/CompressorLanding/ConditionsSummary/CompressorConditionsSummary";
import ValveData from "./pages/CompressorLanding/ValveData/ValveData";
import LubeOilTab from "./pages/CompressorLanding/CompressorLubeOil/LubeOilTab";
import TelemetryDataTab from "./pages/CompressorLanding/TelemetryData/TelemetryDataTab";
import NotificationRulesTabContent from "./pages/CompressorLanding/NotificationRules/NotificationRulesTabContent";

function App() {
    return (
        <div className="app-container">
            <AfmAppBar/>
            <div className="main-content">
                <Routes>
                    <Route path="" element={<Navigate to={"/compressors"}/>}/>
                    <Route path="compressors" element={<CompressorsPage/>}>
                        <Route path=":frameSerialNumber" element={<CompressorLandingPage/>}>
                            <Route path="conditions" element={<CompressorConditionsSummary />} />
                            <Route path="alerts" element={<CompressorAlertsTab />} />
                            <Route path="charts" element={<ChartsTab />} />
                            <Route path="valves" element={<ValveData />} />
                            <Route path="lube" element={<LubeOilTab />} />
                            <Route path="telemetry" element={<TelemetryDataTab />} />
                            <Route path="notifications" element={<NotificationRulesTabContent />} />
                            <Route path="" element={<Navigate to={"conditions"}/>}/>
                        </Route>
                    </Route>
                    <Route path="/alerts" element={<FleetAlertsPage/>}/>
                    <Route path="/reports" element={<ReportsLanding/>}/>
                    <Route path="/troubleshooting" element={<FaultTroubleShootingRepository />}/>
                    {/*<Route path="/notifications" element={<NotificationsLanding/>}/>*/}
                    <Route path="/administration" element={<AdministrationLanding/>}/>
                    <Route path="/administration/adduser/:organizationId" element={<AddUserLanding/>}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;